import { SelectedClearState, SubscriptionDataState } from 'state-domains/domain';
import {
    processLoadedAccountInformation,
    processLoadedActivities,
    processLoadedCoordinates,
    processLoadedDefaultPermissions,
    processLoadedActivityGroups,
    processLoadedFilterOperators,
    processLoadedHeaders,
    processLoadedHeaderTypes,
    processLoadedLists,
    processLoadedCategories,
    processLoadedSystemFilters,
    processLoadedTables,
    processLoadedTableViews,
    updateObjectForAction,
    SubscriptionActionNames,
    processLoadedSampleDispatchHeaders,
    processLoadedColours,
    processLoadedSampleResultsRankedUnits,
    processLoadedSampleWorkflows,
} from 'state-domains/domain/subscription/utils';

import { LOAD_STATUS_COMPLETE, LOAD_STATUS_PENDING, LOAD_STATUS_STALE } from '../../../constants';
import { BaseAction } from '../../../types';
import {
    LOAD_ACCOUNT_ACTIVITIES,
    LOAD_ACCOUNT_INFORMATION,
    LOAD_DEFAULT_PERMISSIONS,
    LOAD_FILTER_OPERATORS,
    LOAD_HEADERS,
    LOAD_HEADER_TYPES,
    LOAD_HEADER_CATEGORIES,
    LOAD_LISTS,
    LOAD_LIST_WITH_ID,
    LOAD_LIST_CATEGORIES,
    LOAD_SYSTEM_FILTERS,
    LOAD_TABLES,
    LOAD_TABLE_CATEGORIES,
    LOAD_TABLE_VIEWS,
    LOGOUT_REQUEST,
    LOAD_SUBSCRIPTION_STATE,
    LOAD_COORDINATES,
    LOAD_ACTIVITY_GROUPS,
    LOAD_COLOURS,
    CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE,
    CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE,
    CLEAR_ALL_CONFIGURATION_ITEMS,
    SELECT_ALL_CONFIGURATION_ITEMS,
    SELECT_CONFIGURATION_ITEMS,
    UNSELECT_CONFIGURATION_ITEMS,
    CREATE_UPDATE_DELETE_KEY_IN_COLLECTION_ITEM_SUBSCRIPTION_STATE,
    CREATE_UPDATE_DELETE_COLLECTION_ITEM_LIST_SUBSCRIPTION_STATE,
    LOAD_ACTIVITY_CATEGORIES,
    LOAD_SAMPLE_DISPATCH_HEADERS,
    LOAD_SAMPLE_RESULTS_RANKED_UNITS,
    LOAD_SAMPLE_WORKFLOWS,
    LOAD_RELATED_COLLECTIONS_STATE,
    LOAD_ACCOUNT_ACTIVITY,
} from '../../../types/actionTypes';
import {
    completeReducer,
    createError,
    failureReducer,
    mappedReducer,
    pendingReducer,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import { Account, SubscriptionState } from '../types';

export const INITIAL_SUBSCRIPTION_DATA_STATE = {
    status: LOAD_STATUS_PENDING,
    error: null,
};

export const INITIAL_RELATED_COLLECTIONS_STATE = {
    status: LOAD_STATUS_COMPLETE,
    error: null,
};

const INITIAL_ACCOUNT_STATE: Account = {
    id: '',
    identityServerId: '',
    subscriptions: {},
    migratedToGeosoft: false,
    name: '',
    dbid: '',
    domain: '',
    eap: {
        accessNewApp: false,
    },
};
const INITIAL_SUBSCRIPTION_CLEAR_STATE: SelectedClearState = {
    isSelectedAll: false,
    isClearedAll: true,
    selectedItems: {},
    unSelectedItems: {},
    status: LOAD_STATUS_PENDING,
    error: null,
};
export const INITIAL_STATE: SubscriptionState = {
    status: LOAD_STATUS_PENDING,
    error: null,
    singleItemLoadState: { status: LOAD_STATUS_STALE, error: null },
    selectedClearState: INITIAL_SUBSCRIPTION_CLEAR_STATE,
    subscriptionDataState: INITIAL_SUBSCRIPTION_DATA_STATE,
    relatedCollectionsState: INITIAL_RELATED_COLLECTIONS_STATE,
    activities: {},
    account: INITIAL_ACCOUNT_STATE,
    users: {},
    tableViews: {},
    lists: {},
    listCategories: {},
    tableCategories: {},
    headerCategories: {},
    activityCategories: {},
    tables: {},
    headers: {},
    headerTypes: {},
    systemFilters: {},
    filterOperators: {},
    defaultPermissions: {},
    coordinates: {},
    activityGroups: {},
    customColours: {},
    mxColours: {},
    units: [],
    sampleDispatchHeaders: {},
    sampleWorkflows: {},
};
const clearAllItemsCompleteReducer = (state: SubscriptionState, _action: BaseAction) => ({
    ...state,
    selectedClearState: {
        isSelectedAll: false,
        isClearedAll: true,
        selectedItems: {},
        unSelectedItems: {},
    },
});

const selectItemsCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const { items } = action.payload;
    const { selectedClearState } = { ...state };
    const unSelectedItems = { ...selectedClearState.unSelectedItems };
    const selectedItems = { ...selectedClearState.selectedItems };
    for (const item of items) {
        selectedItems[item.id] = item;
        delete unSelectedItems[item.id];
    }
    return {
        ...state,
        selectedClearState: {
            selectedItems,
            unSelectedItems,
            isClearedAll: false,
        },
    };
};
const selectAllItemsCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const { items } = action.payload;
    const { selectedClearState } = { ...state };
    const selectedItems = { ...selectedClearState.selectedItems };
    for (const item of items) {
        selectedItems[item.id] = item;
    }
    return {
        ...state,
        selectedClearState: {
            isSelectedAll: true,
            isClearedAll: false,
            selectedItems,
            unSelectedItems: {},
        },
    };
};
const unSelectItemsCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const { items } = action.payload;
    const { selectedClearState } = { ...state };
    const unSelectedItems = { ...selectedClearState.unSelectedItems };
    const selectedItems = { ...selectedClearState.selectedItems };
    const isSelectedAll = selectedClearState.isSelectedAll;
    for (const item of items) {
        unSelectedItems[item.id] = item;
        delete selectedItems[item.id];
    }
    return {
        ...state,
        selectedClearState: {
            unSelectedItems,
            selectedItems,
            isSelectedAll: isSelectedAll && Object.keys(selectedItems).length !== 0,
        },
    };
};

const subscriptionStatePendingReducer = (state: SubscriptionState, _action: BaseAction) => ({
    ...state,
    subscriptionDataState: pendingReducer({}),
});

const subscriptionStateCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const {
        type,
        payload: items = [] as {
            result: any;
            actionName: string;
            processResultFunction: Function;
        }[],
    } = action;
    const getObjectFromItems = (
        key: string,
        items: {
            result: any;
            actionName: string;
            processResultFunction: Function;
        }[],
    ) => {
        for (const item of items) {
            if (item.actionName === key) {
                return item;
            }
        }
        return null;
    };
    const subState = {} as SubscriptionDataState;
    // Process array of results in order
    for (const key in SubscriptionActionNames) {
        const item = getObjectFromItems(key, items);
        if (item) {
            const { result, actionName, processResultFunction } = item;
            const processedResult = processResultFunction(subState, { payload: { result } });
            updateObjectForAction(subState, processedResult, actionName);
        }
    }

    return {
        ...state,
        ...subState,
        [type === typeComplete(LOAD_RELATED_COLLECTIONS_STATE)
            ? 'relatedCollectionsState'
            : 'subscriptionDataState']: completeReducer({}),
    };
};

const subscriptionStateFailureReducer = (state: SubscriptionState, action: BaseAction) => ({
    ...state,
    subscriptionDataState: failureReducer({
        ...state.subscriptionDataState,
        error: createError(action),
    }),
});

const loadRelatedCollectionsPendingReducer = (state: SubscriptionState, _action: BaseAction) => ({
    ...state,
    relatedCollectionsState: pendingReducer({}),
});

const loadRelatedCollectionsFailureReducer = (state: SubscriptionState, action: BaseAction) => ({
    ...state,
    relatedCollectionsState: failureReducer({
        ...state.relatedCollectionsState,
        error: createError(action),
    }),
});

const loadActivitiesPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadActivitiesCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedActivities(state, action);

    return completeReducer({
        ...state,
        activities: dict,
    });
};

const loadActivitiesFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadActivityPendingReducer = (state: SubscriptionState, _action: BaseAction) => ({
    ...state,
    singleItemLoadState: pendingReducer({}),
});

const loadActivityCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const { result } = action.payload;

    return {
        ...state,
        activities: { ...state.activities, [result.id]: result },
        singleItemLoadState: completeReducer({}),
    };
};

const loadActivityFailureReducer = (state: SubscriptionState, action: BaseAction) => ({
    ...state,
    singleItemLoadState: failureReducer({ error: action.payload.error }),
});

const loadAccountInformationPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadAccountInformationCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const { account, usersMap } = processLoadedAccountInformation(state, action);

    return completeReducer({
        ...state,
        account,
        users: usersMap,
    });
};

const loadAccountInformationFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadTableViewsPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadTableViewsCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedTableViews(state, action);

    return completeReducer({ ...state, tableViews: dict });
};

const loadTableViewsFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadTablesPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadTablesCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedTables(state, action);

    return completeReducer({ ...state, tables: dict });
};

const loadTablesFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadTableCategoriesFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadTableCategoriesPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadTableCategoriesCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedCategories(state, action);

    return completeReducer({ ...state, tableCategories: dict });
};

const loadListsPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadListsCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedLists(state, action);

    return completeReducer({ ...state, lists: dict });
};

const loadListsFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadListWithIdPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadListWithIdCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const { payload } = action;

    return completeReducer({ ...state, lists: { ...state.lists, [payload.id]: payload } });
};

const loadListWithIdFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadListCategoriesFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadListCategoriesPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadListCategoriesCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedCategories(state, action);

    return completeReducer({ ...state, listCategories: dict });
};

const loadActivityCategoriesFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadActivityCategoriesPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadActivityCategoriesCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedCategories(state, action);
    return completeReducer({ ...state, activityCategories: dict });
};

const loadHeaderCategoriesFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadHeaderCategoriesPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadHeaderCategoriesCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedCategories(state, action);

    return completeReducer({ ...state, headerCategories: dict });
};

const loadHeadersPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadHeadersCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedHeaders(state, action);

    return completeReducer({ ...state, ...dict });
};

const loadHeadersFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadHeaderTypesPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadHeaderTypesCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedHeaderTypes(state, action);

    return completeReducer({ ...state, headerTypes: dict });
};

const loadHeaderTypesFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadSystemFiltersPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadSystemFiltersFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadSystemFiltersCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedSystemFilters(state, action);

    return completeReducer({
        ...state,
        systemFilters: dict,
    });
};

const loadFilterOperatorsFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadFilterOperatorsPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadFilterOperatorsCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedFilterOperators(state, action);

    return completeReducer({
        ...state,
        filterOperators: dict,
    });
};

const loadDefaultPermissionsFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadDefaultPermissionsPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadDefaultPermissionsCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedDefaultPermissions(state, action);

    return completeReducer({
        ...state,
        defaultPermissions: dict,
    });
};

const loadCoordinatesFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadCoordinatesPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadCoordinatesCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedCoordinates(state, action);

    return completeReducer({
        ...state,
        ...dict,
    });
};

const loadActivityGroupsFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadActivityGroupsPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadActivityGroupsCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedActivityGroups(state, action);

    return completeReducer({
        ...state,
        activityGroups: dict,
    });
};

const loadColoursFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadColoursPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadColoursCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedColours(state, action);
    return completeReducer({
        ...state,
        ...dict,
    });
};

const loadSampleResultsRankedUnitsFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadSampleResultsRankedUnitsPendingReducer = (
    state: SubscriptionState,
    _action: BaseAction,
) => pendingReducer(state);

const loadSampleResultsRankedUnitsCompleteReducer = (
    state: SubscriptionState,
    action: BaseAction,
) => {
    const dict = processLoadedSampleResultsRankedUnits(state, action);
    return completeReducer({
        ...state,
        ...dict,
    });
};

const loadSampleDispatchHeadersPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadSampleDispatchHeadersCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedSampleDispatchHeaders(state, action);

    return completeReducer({ ...state, ...dict });
};

const loadSampleDispatchHeadersFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const loadSamplWorkflowsPendingReducer = (state: SubscriptionState, _action: BaseAction) =>
    pendingReducer(state);

const loadSampleWorkflowsCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const dict = processLoadedSampleWorkflows(state, action);

    return completeReducer({ ...state, ...dict });
};

const loadSampleWorkflowsFailureReducer = (state: SubscriptionState, action: BaseAction) =>
    failureReducer({ ...state, error: createError(action) });

const cudCollectionItemCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    // Create/Update/Delete an item in subscription state collection. Operates on the complete
    // first level object in collection
    const { id, item, type, collectionName } = action.payload;
    const collection = { ...(state[collectionName as keyof SubscriptionState] as any) };
    if (type === 'DELETE') {
        delete collection[id];
    } else {
        collection[id] = item;
    }

    return {
        ...state,
        [collectionName]: collection,
    };
};

const cudKeyInCollectionItem = (state: SubscriptionState, actions: BaseAction) => {
    // Create/Update/Delete a key for an item in one of subscription state collections. Operates
    // on the complete 2nd level object in collection
    const { id, collectionName, item, keyInCollection, type } = actions.payload;
    const collection = { ...(state[collectionName as keyof SubscriptionState] as any) };
    const collectionItem = { ...collection[id] };
    if (type === 'DELETE') {
        delete collectionItem[keyInCollection];
    } else {
        collectionItem[keyInCollection] = item;
    }
    collection[id] = collectionItem;

    return completeReducer({
        ...state,
        [collectionName]: collection,
    });
};

const cudNestedItem = (state: SubscriptionState, actions: BaseAction) => {
    // Create/Update/Delete a nested item in one of the collections. Can work to delete only
    // parts of object by going over the keys
    const {
        id,
        collectionName,
        item,
        items = [],
        keyInCollection,
        childKey = '',
        childId = '',
        deleteIds = [],
    } = actions.payload;

    const collection = { ...(state[collectionName as keyof SubscriptionState] as any) };
    const collectionItem = { ...collection[id] };

    let tempItem = collectionItem;

    keyInCollection.split('.').forEach((x: any) => {
        if (tempItem) {
            tempItem = tempItem[x];
        }
    });

    const workItem = childKey && childId ? tempItem[childId][childKey] : tempItem;

    deleteIds.forEach((x: string) => {
        delete workItem[x];
    });

    if (items.length) {
        items.forEach((x: any) => (workItem[x.id] = x));
    } else if (item) {
        workItem[item.id] = item;
    }

    collection[id] = collectionItem;

    return completeReducer({
        ...state,
        [collectionName]: collection,
    });
};

const cudCollectionItemListCompleteReducer = (state: SubscriptionState, action: BaseAction) => {
    const { items, collectionName, type } = action.payload;
    const collection = { ...(state[collectionName as keyof SubscriptionState] as any) };

    if (type === 'DELETE') {
        items.forEach((item: any) => {
            delete collection[item.id];
        });
    } else {
        items.forEach((item: any) => {
            collection[item.id] = item;
        });
    }

    return {
        ...state,
        [collectionName]: collection,
    };
};

export const reducer = mappedReducer(INITIAL_STATE, {
    [SELECT_ALL_CONFIGURATION_ITEMS]: selectAllItemsCompleteReducer,
    [CLEAR_ALL_CONFIGURATION_ITEMS]: clearAllItemsCompleteReducer,
    [SELECT_CONFIGURATION_ITEMS]: selectItemsCompleteReducer,
    [UNSELECT_CONFIGURATION_ITEMS]: unSelectItemsCompleteReducer,

    [typeComplete(LOGOUT_REQUEST)]: () => INITIAL_STATE,

    [typePending(LOAD_SUBSCRIPTION_STATE)]: subscriptionStatePendingReducer,
    [typeComplete(LOAD_SUBSCRIPTION_STATE)]: subscriptionStateCompleteReducer,
    [typeFail(LOAD_SUBSCRIPTION_STATE)]: subscriptionStateFailureReducer,

    [typePending(LOAD_ACCOUNT_ACTIVITIES)]: loadActivitiesPendingReducer,
    [typeComplete(LOAD_ACCOUNT_ACTIVITIES)]: loadActivitiesCompleteReducer,
    [typeFail(LOAD_ACCOUNT_ACTIVITIES)]: loadActivitiesFailureReducer,

    [typePending(LOAD_ACCOUNT_ACTIVITY)]: loadActivityPendingReducer,
    [typeComplete(LOAD_ACCOUNT_ACTIVITY)]: loadActivityCompleteReducer,
    [typeFail(LOAD_ACCOUNT_ACTIVITY)]: loadActivityFailureReducer,

    [typePending(LOAD_ACCOUNT_INFORMATION)]: loadAccountInformationPendingReducer,
    [typeComplete(LOAD_ACCOUNT_INFORMATION)]: loadAccountInformationCompleteReducer,
    [typeFail(LOAD_ACCOUNT_INFORMATION)]: loadAccountInformationFailureReducer,

    [typePending(LOAD_TABLE_VIEWS)]: loadTableViewsPendingReducer,
    [typeComplete(LOAD_TABLE_VIEWS)]: loadTableViewsCompleteReducer,
    [typeFail(LOAD_TABLE_VIEWS)]: loadTableViewsFailureReducer,

    [typePending(LOAD_TABLES)]: loadTablesPendingReducer,
    [typeComplete(LOAD_TABLES)]: loadTablesCompleteReducer,
    [typeFail(LOAD_TABLES)]: loadTablesFailureReducer,

    [typePending(LOAD_HEADER_CATEGORIES)]: loadHeaderCategoriesPendingReducer,
    [typeComplete(LOAD_HEADER_CATEGORIES)]: loadHeaderCategoriesCompleteReducer,
    [typeFail(LOAD_HEADER_CATEGORIES)]: loadHeaderCategoriesFailureReducer,

    [typePending(LOAD_TABLE_CATEGORIES)]: loadTableCategoriesPendingReducer,
    [typeComplete(LOAD_TABLE_CATEGORIES)]: loadTableCategoriesCompleteReducer,
    [typeFail(LOAD_TABLE_CATEGORIES)]: loadTableCategoriesFailureReducer,

    [typePending(LOAD_LIST_CATEGORIES)]: loadListCategoriesPendingReducer,
    [typeComplete(LOAD_LIST_CATEGORIES)]: loadListCategoriesCompleteReducer,
    [typeFail(LOAD_LIST_CATEGORIES)]: loadListCategoriesFailureReducer,

    [typePending(LOAD_ACTIVITY_CATEGORIES)]: loadActivityCategoriesPendingReducer,
    [typeComplete(LOAD_ACTIVITY_CATEGORIES)]: loadActivityCategoriesCompleteReducer,
    [typeFail(LOAD_ACTIVITY_CATEGORIES)]: loadActivityCategoriesFailureReducer,

    [typePending(LOAD_LISTS)]: loadListsPendingReducer,
    [typeComplete(LOAD_LISTS)]: loadListsCompleteReducer,
    [typeFail(LOAD_LISTS)]: loadListsFailureReducer,

    [typePending(LOAD_LIST_WITH_ID)]: loadListWithIdPendingReducer,
    [typeComplete(LOAD_LIST_WITH_ID)]: loadListWithIdCompleteReducer,
    [typeFail(LOAD_LIST_WITH_ID)]: loadListWithIdFailureReducer,

    [typePending(LOAD_HEADERS)]: loadHeadersPendingReducer,
    [typeComplete(LOAD_HEADERS)]: loadHeadersCompleteReducer,
    [typeFail(LOAD_HEADERS)]: loadHeadersFailureReducer,

    [typePending(LOAD_HEADER_TYPES)]: loadHeaderTypesPendingReducer,
    [typeComplete(LOAD_HEADER_TYPES)]: loadHeaderTypesCompleteReducer,
    [typeFail(LOAD_HEADER_TYPES)]: loadHeaderTypesFailureReducer,

    [typePending(LOAD_SYSTEM_FILTERS)]: loadSystemFiltersPendingReducer,
    [typeComplete(LOAD_SYSTEM_FILTERS)]: loadSystemFiltersCompleteReducer,
    [typeFail(LOAD_SYSTEM_FILTERS)]: loadSystemFiltersFailureReducer,

    [typePending(LOAD_FILTER_OPERATORS)]: loadFilterOperatorsPendingReducer,
    [typeComplete(LOAD_FILTER_OPERATORS)]: loadFilterOperatorsCompleteReducer,
    [typeFail(LOAD_FILTER_OPERATORS)]: loadFilterOperatorsFailureReducer,

    [typePending(LOAD_DEFAULT_PERMISSIONS)]: loadDefaultPermissionsPendingReducer,
    [typeComplete(LOAD_DEFAULT_PERMISSIONS)]: loadDefaultPermissionsCompleteReducer,
    [typeFail(LOAD_DEFAULT_PERMISSIONS)]: loadDefaultPermissionsFailureReducer,

    [typePending(LOAD_COORDINATES)]: loadCoordinatesPendingReducer,
    [typeComplete(LOAD_COORDINATES)]: loadCoordinatesCompleteReducer,
    [typeFail(LOAD_COORDINATES)]: loadCoordinatesFailureReducer,

    [typePending(LOAD_ACTIVITY_GROUPS)]: loadActivityGroupsPendingReducer,
    [typeComplete(LOAD_ACTIVITY_GROUPS)]: loadActivityGroupsCompleteReducer,
    [typeFail(LOAD_ACTIVITY_GROUPS)]: loadActivityGroupsFailureReducer,

    [typePending(LOAD_COLOURS)]: loadColoursPendingReducer,
    [typeComplete(LOAD_COLOURS)]: loadColoursCompleteReducer,
    [typeFail(LOAD_COLOURS)]: loadColoursFailureReducer,

    [typePending(LOAD_SAMPLE_DISPATCH_HEADERS)]: loadSampleDispatchHeadersPendingReducer,
    [typeComplete(LOAD_SAMPLE_DISPATCH_HEADERS)]: loadSampleDispatchHeadersCompleteReducer,
    [typeFail(LOAD_SAMPLE_DISPATCH_HEADERS)]: loadSampleDispatchHeadersFailureReducer,

    [typePending(LOAD_SAMPLE_RESULTS_RANKED_UNITS)]: loadSampleResultsRankedUnitsPendingReducer,
    [typeComplete(LOAD_SAMPLE_RESULTS_RANKED_UNITS)]: loadSampleResultsRankedUnitsCompleteReducer,
    [typeFail(LOAD_SAMPLE_RESULTS_RANKED_UNITS)]: loadSampleResultsRankedUnitsFailureReducer,

    [typePending(LOAD_SAMPLE_WORKFLOWS)]: loadSamplWorkflowsPendingReducer,
    [typeComplete(LOAD_SAMPLE_WORKFLOWS)]: loadSampleWorkflowsCompleteReducer,
    [typeFail(LOAD_SAMPLE_WORKFLOWS)]: loadSampleWorkflowsFailureReducer,

    [typePending(LOAD_RELATED_COLLECTIONS_STATE)]: loadRelatedCollectionsPendingReducer,
    [typeFail(LOAD_RELATED_COLLECTIONS_STATE)]: loadRelatedCollectionsFailureReducer,
    [typeComplete(LOAD_RELATED_COLLECTIONS_STATE)]: subscriptionStateCompleteReducer,

    [typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE)]:
        cudCollectionItemCompleteReducer,
    [typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE)]: cudNestedItem,
    [typeComplete(CREATE_UPDATE_DELETE_KEY_IN_COLLECTION_ITEM_SUBSCRIPTION_STATE)]:
        cudKeyInCollectionItem,

    [typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_LIST_SUBSCRIPTION_STATE)]:
        cudCollectionItemListCompleteReducer,
});
